import Vue from 'vue';
import VueProgressBar from 'vue-progressbar';
import Toasted from 'vue-toasted';
import VuejsDialog from 'vuejs-dialog';
import vClickOutside from 'v-click-outside';
import InlineSvg from 'vue-inline-svg';
import Donut from 'vue-css-donut-chart';
import ProgressDonut from './views/ProgressDonut.vue';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './i18n';
import ErrorFilter from './common/error.filter';
import 'vue-css-donut-chart/dist/vcdonut.css';
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import { SsoService } from './common/api.service';

// Tell Vue to install the plugin.
Vue.use(VuejsDialog);
Vue.use(Donut);
Vue.use(vClickOutside);
Vue.component('ProgressDonut', ProgressDonut);
Vue.component('inline-svg', InlineSvg);

require('./assets/scss/bootstrap-grid.scss');
require('./assets/scss/bootstrap.scss');
require('./assets/scss/style.scss');
require('./assets/scss/override.scss');

Vue.use(Toasted, {
  position: 'top-center',
  className: 'alert',
  duration: 2000,
});

const options = {
  color: '#2C7BAE',
  failedColor: '#fd397a',
  thickness: '3px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
};

Vue.use(VueProgressBar, options);
Vue.config.productionTip = false;
Vue.filter('error', ErrorFilter);
Vue.filter('str_limit', (value, size) => {
  if (!value) return '';
  const text = value.toString();

  if (text.length <= size) {
    return text;
  }
  return `${text.substr(0, size)}[...]`;
});

SsoService.init();
// ApiService.init();

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
