import Vue from 'vue';
import VueRouter from 'vue-router';
import psl from 'psl';
import {
  CALLBACK,
  CHECK_AUTH,
  CHECK_ORGANIZATION_ACCESS,
  CHECK_ORGANIZATION_DOMAIN, FETCH_COURSES,
  FETCH_ORGANIZATIONS, FETCH_SESSIONS,
  INVITE_TO_SESSION,
  LOGIN,
  LOGOUT, SET_COURSE, SET_SESSION,
} from '@/store/actions.type';
import { destroyUrlReferer, getUrlReferer, saveUrlReferer } from '@/common/referer.service';
import store from '@/store';
import {
  PURGE_AUTH,
  SET_LOGIN,
  SET_ORGANIZATION,
  SET_ORGANIZATION_ID,
  SET_ORGANIZATIONS, SET_PASSWORD,
  SET_PORTAL,
} from '@/store/mutations.type';
import { saveToken } from '@/common/jwt.service';
import i18n from '@/i18n';
import { ROLE_ADMIN_PERFORM, ROLE_PLAYER } from '@/common/userRoles';
import { getOrganization, getOrganizations } from '@/common/organization.service';
import { API_URL, REDIRECT_URI } from '@/common/config';

Vue.use(VueRouter);

const routes = [
  {
    path: '/my-organization',
    name: 'Homepage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Homepage.vue'),
    meta: {
      needGuard: true,
      needOrganization: true,
      title: 'Homepage',
    },
  },
  {
    path: '/my-organization/sessions/:sessionId',
    component: () => import(/* webpackChunkName: "about" */ '../views/SessionView.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.sessions.length === 0) {
        store.dispatch(FETCH_SESSIONS)
          .then(() => {
            store.dispatch(SET_SESSION, to.params.sessionId)
              .then(() => {
                if (!to.params.courseId) {
                  next({ name: 'CourseView', params: { sessionId: to.params.sessionId, courseId: to.params.courseId ?? 0 } });
                }
                next();
              })
              .catch(() => next({ name: 'Homepage' }));
          })
          .catch(() => next({ name: 'Homepage' }));
      } else if (!store.getters.currentSession
        || (store.getters.currentSession.id !== to.params.sessionId)) {
        store.dispatch(SET_SESSION, to.params.sessionId)
          .then(() => {
            if (!to.params.courseId) {
              next({ name: 'CourseView', params: { sessionId: to.params.sessionId, courseId: to.params.courseId ?? 0 } });
            }
            next();
          })
          .catch(() => next({ name: 'Homepage' }));
      } else {
        if (to.params.courseId === null) {
          next({ name: 'CourseView', params: { sessionId: to.params.sessionId, courseId: to.params.courseId ?? 0 } });
        }
        next();
      }
    },
    name: 'SessionView',
    meta: {
      needGuard: true,
      needOrganization: true,
    },
    children: [
      {
        path: ':courseId?',
        component: () => import(/* webpackChunkName: "about" */ '../views/CourseView.vue'),
        name: 'CourseView',
        beforeEnter: (to, from, next) => {
          store.dispatch(FETCH_COURSES).then((courseResponse) => {
            const course = (to.params.courseId === 0 || to.params.courseId === '0')
              ? courseResponse.data.lastCourseId
              : to.params.courseId;
            store.dispatch(SET_COURSE, course)
              .then(() => next())
              .catch(() => next({ name: 'Homepage' }));
          }).catch(() => {
            next({ name: 'Homepage' });
          });
        },
      },
    ],
  },
  {
    path: '/fromperform/:id',
    beforeEnter: (to, from, next) => {
      store.dispatch(SET_SESSION, null);
      store.dispatch(SET_COURSE, null);
      const orgId = to.params.id;
      store.dispatch(FETCH_ORGANIZATIONS, {})
        .then(() => {
          store.commit(SET_ORGANIZATION_ID, orgId);
          next({ name: 'Homepage' });
        });
    },
    meta: {
      needGuard: true,
      title: 'Go to instance',
    },
  },
  {
    path: '/fromperform/:id/:session',
    beforeEnter: (to, from, next) => {
      store.dispatch(SET_SESSION, null);
      store.dispatch(SET_COURSE, null);
      const orgId = to.params.id;
      const sessionId = to.params.session;
      store.dispatch(FETCH_ORGANIZATIONS, {})
        .then(() => {
          store.commit(SET_ORGANIZATION_ID, orgId);
          next({
            path: `/my-organization/sessions/${sessionId}`,
            query: to.query,
          });
        });
    },
    meta: {
      needGuard: true,
      title: 'Go to session',
    },
  },
  {
    path: '/invitetosession/:code',
    beforeEnter: (to, from, next) => {
      store.dispatch(SET_SESSION, null);
      store.dispatch(SET_COURSE, null);
      store.dispatch(INVITE_TO_SESSION, to.params.code)
        .then((data) => {
          if (data.status === 'success') {
            const { sessionId } = data.data;
            const { orgId } = data.data;
            store.dispatch(FETCH_ORGANIZATIONS, {})
              .then(() => {
                store.commit(SET_ORGANIZATION_ID, orgId);
                next({ name: 'Join-Session', params: { status: 'success', session: sessionId } });
              });
          }
        }).catch((data) => {
          next({ name: 'Join-Session', params: { status: 'error', code: data.response.data.code } });
        });
    },
    meta: {
      needGuard: true,
      title: 'Invite To Session',
    },
  },
  {
    path: '/samlredirect/:token',
    name: 'redirect',
    beforeEnter: (to, from, next) => {
      saveToken(to.params.token);
      store.dispatch(CHECK_AUTH)
        .then(() => {
          const refererUrl = getUrlReferer();
          next({ path: refererUrl });
        });
    },
    meta: {
      title: 'SAML Redirect',
    },
  },
  {
    path: '/',
    name: 'Organizations',
    component: () => import(/* webpackChunkName: "about" */ '../views/Organizations.vue'),
    meta: {
      needGuard: true,
      title: 'My organizations',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: {
      needGuard: false,
      title: 'logout',
    },
    beforeEnter: () => store.dispatch(LOGOUT),
  },
  {
    path: '/callback-login',
    name: 'Callback-login',
    // route level code-splitting
    beforeEnter: (to, from, next) => store.dispatch(CALLBACK,
      { code: to.query.code })
      .then(() => {
        const refererUrl = getUrlReferer();

        if (refererUrl) {
          destroyUrlReferer();
          return next(refererUrl);
        }
        return next('/');
      })
      .catch(() => {
        store.dispatch(LOGOUT).then();
      }),
    meta: {
      needGuard: false,
      title: 'login',
    },
  },
  {
    path: '/403',
    name: 'AccessDenied',
    component: () => import('../views/AccessDenied.vue'),
    meta: {
      needGuard: false,
      title: 'AccessDenied',
    },
  },
  {
    path: '/login',
    name: 'Custom Login',
    component: () => import('../views/CustomLogin.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters.organization || !store.getters.organizationId
        || !store.getters.organizations) {
        next('/');
      } else {
        store.commit(PURGE_AUTH);
        const organization = getOrganization();
        let redirectUrl = null;
        const forcedIdp = organization.idps.find((idp) => idp.forceUse);
        if (forcedIdp) {
          redirectUrl = `${API_URL}/saml/${forcedIdp.identifier}/login`;
        }
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else {
          next();
        }
      }
    },
    meta: {
      needGuard: false,
      needOrganization: true,
      title: 'Login',
    },
  },
  {
    path: '/JoinSession',
    name: 'Join-Session',
    component: () => import('../views/JoinSession.vue'),
    meta: {
      needGuard: true,
      title: 'Join Session',
    },
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
    meta: {
      needGuard: false,
      title: 'NotFound',
    },
  },
];

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes,
});
let domainVerified = false;
let orgVerified = false;
let customDomain = false;
function changeFavicon(fullDomain) {
  const parsed = psl.parse(fullDomain);
  const domain = parsed.domain ?? window.location.hostname;
  if (domain && domain !== 'vtsperform.com') {
    const favIcon = document.getElementById('favicon');
    const url = `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&size=16&url=https://${domain}`;
    favIcon.setAttribute('href', url);
  }
}
router.beforeEach(async (to, from, next) => {
  if (!domainVerified) {
    const domain = window.location.hostname;
    const performURL = new URL(REDIRECT_URI);
    if (domain !== performURL.hostname) {
      changeFavicon(domain);
      await store.dispatch(CHECK_ORGANIZATION_DOMAIN, { domain })
        .then(() => {
          domainVerified = true;
          customDomain = true;
        }).catch(() => {
          domainVerified = true;
          next('/403');
        });
    } else {
      domainVerified = true;
      customDomain = false;
    }
  }
  if (to.matched.some((m) => m.meta.needGuard)) {
    if (to.query.token) {
      saveToken(to.query.token);
    }
    if (to.query.login) {
      store.commit(SET_LOGIN, to.query.login);
    }
    if (to.query.password) {
      store.commit(SET_PASSWORD, to.query.password);
    }
    store.dispatch(CHECK_AUTH)
      .then(() => {
        i18n.locale = store.state.auth.locale;
        const { roles } = store.state.auth.user;
        if (roles.indexOf(ROLE_ADMIN_PERFORM) === -1 && roles.indexOf(ROLE_PLAYER) === -1) {
          next('/403');
        }

        let needOrganization;
        if (to.matched.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          needOrganization = to.meta.needOrganization;
        } else {
          needOrganization = to.matched.some((m) => m.meta.needOrganization);
        }

        if (needOrganization) {
          if (!store.getters.organization || !store.getters.organizationId
            || !store.getters.organizations) {
            const organization = getOrganization();
            const organizations = getOrganizations();
            if (organization && organizations) {
              store.commit(SET_ORGANIZATION, organization);
              store.commit(SET_ORGANIZATIONS, organizations);
              next();
            } else {
              next({ name: 'Organizations' });
            }
          } else if (!orgVerified) {
            store.dispatch(CHECK_ORGANIZATION_ACCESS).then(() => {
              orgVerified = true;
              next();
            }).catch(() => {
              orgVerified = true;
              next('/403');
            });
          }
        }
        if (!needOrganization || orgVerified) {
          if (customDomain && to.path === '/') {
            next('/my-organization');
          } else {
            next();
          }
        }
      })
      .catch(() => {
        if (to.query.portal) {
          store.commit(SET_PORTAL, to.query.portal);
        }
        if (to.meta.needGuard) {
          saveUrlReferer(to.path);
        }
        if (customDomain) {
          const lang = navigator.language.split('-')[0];
          i18n.locale = lang;
          next({ path: '/login' });
        } else {
          store.dispatch(LOGIN);
        }
      });
  } else {
    next();
  }
});

export default router;
